body {
  background: linear-gradient(to bottom right, #7ca1ff, #dbe5ff);
  padding: 20px 20px 20px 20px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    width: 2rem;
    height: 2rem;
  }
}

/*
  background-image: url(./img/back.webp);
*/

.App-header {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  /* color: #61dafb; */
  font-size: 1.2rem;
  text-shadow: 0 0 15px #444, 0 0 15px #333, 0 0 15px #222;
  color: yellow;
  text-decoration: none;
  margin: 0rem 1rem;
}

.App-link:hover {
  transition: text-shadow .5s;
  text-shadow: 0 0 15px black, 0 0 15px black, 0 0 15px #222;
  color: white
}

.txt-shadow {
  text-shadow: 0 0 15px #444, 0 0 15px #333, 0 0 15px #222;
}

.search-icon {
  border: 2px solid #666;
  width: 32px;
  height: 32px;
  margin: 10px 10px;
}

.search-icon:hover {
  border: 2px solid #444;
}

form {
  padding: 1.5rem;
  margin: 2rem 0rem;
  background-color: rgba(90, 90, 90, 0.7);
}


@media (max-width: 399px) {

  .form-input {
    width: 250px;
    border: none;
    border-radius: 10px;
    outline: none;
    padding: 10px;
    font-size: 1.2rem;
    color: #676767;
    border: solid 3px #666;
    box-sizing: border-box;
  }
}

@media (min-width: 400px) {

  .form-input {
    width: 300px;
    border: none;
    border-radius: 10px;
    outline: none;
    padding: 10px;
    font-size: 1.2rem;
    color: #676767;
    border: solid 3px #666;
    box-sizing: border-box;
  }

}

@media (min-width: 600px) {

  .form-input {
    width: 350px;
    border: none;
    border-radius: 10px;
    outline: none;
    padding: 10px;
    font-size: 1.2rem;
    color: #676767;
    border: solid 3px #666;
    box-sizing: border-box;
  }

}

input[type="text"]:focus {
  background-color: #ffffcc;
  border: solid 3px green;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}